body {
  margin: 0;
  padding: 0;
}

/* App.css or global CSS file */
body, * {
font-family: 'Poppins', sans-serif;
}


/* Customize the navigation buttons (arrows) to be white */
.swiper-button-next,
.swiper-button-prev {
color: #fff; /* Set the navigation arrow color to white */
font-size: 16px; /* Adjust the font size */
padding: 0; /* Remove padding to make the button more compact */
}

/* Ensure the correct padding for desktop */
@media (min-width: 1024px) {
.swiper {
  padding-left: 150px !important;
  padding-right: 150px !important;
}
}

/* Optional: Adjust the size of the button hover effect */
.swiper-button-next:hover,
.swiper-button-prev:hover {
transform: scale(1.1); /* Slightly enlarge on hover */
}


/* Mengatur kursor untuk canvas pie chart */
canvas {
  cursor: pointer;
}
